.workContainer {
  display: grid;
  place-items: center;
  padding: 3%;
  margin-left: 0.8rem;
}

.projectsHeader {
  padding-left: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .projectsHeader {
    font-size: 24px;
  }
}
