.skillsContainer {
  margin: 0px;
  padding: 0.25%;
  display: grid;
  place-items: center;
  font-weight: 600;
}

.skillsBackground {
  background-color: #f4aafe;
  padding-left: 1.5rem;
  padding-right: 20px;
  padding-top: 10px;
}

@media only screen and (max-width: 820px) {
  .skillsHeader {
    font-size: 24px;
  }
}
