.iconsContainer {
  display: flex;
  flex-wrap: wrap;
  color: #4345fc;
  gap: 30px;
  margin-bottom: 20px;
}

.svg {
  width: 70px;
  margin: 0px;
  color: #4345fc;
  padding: 0px;
}

.svgContainer {
  display: grid;
  place-items: center;
}

@media only screen and (max-width: 820px) {
  .svg {
    width: 43px;
  }
  .iconsContainer {
    justify-content: center;
  }
}
