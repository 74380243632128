.homeContainer {
  background-color: #fce9ff;
  padding: 3%;
  padding-left: 15%;
  padding-bottom: 100px;
  padding-top: 4%;
}

.name {
  font-size: 100px;
  margin: 0px;
}

.viewWorkButton {
  background-color: #f4aafe;
  color: white;
  padding: 14px;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 20px;
  cursor: pointer;
}

.viewWorkButton:hover {
  background-color: #f396ff;
  transition: 0.5s;
  color: white;
}

.homeSentence {
  display: flex;
  font-size: 30px;
  margin: 0px;
  padding: 0px;
  padding-bottom: 40px;
}

.rotateText {
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 400;
}

.iAm {
  margin-top: 0px;
  margin-bottom: 22px;
  font-weight: 400;
}

.appHeader {
  background-color: yellow;
  width: 40%;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .name {
    font-size: 50px;
  }
  .iAm {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .rotateText {
    font-size: 25px;
  }
  .viewWorkButton {
    padding: 0.7rem;
    font-weight: 600;
    border-radius: 1rem;
    font-size: 15px;
    cursor: pointer;
    margin: 0px;
  }
  .homeContainer {
    padding-bottom: 13%;
    padding-left: 10%;
  }
}
