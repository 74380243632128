.headshot {
  width: 32%;
  border-radius: 400px;
  height: 2%;
}

.aboutMe {
  display: flex;
  margin-right: 10%;
  margin-left: 10%;
  align-items: center;
  padding-bottom: 20px;
}

.aboutMeContent {
  padding-left: 1.5rem;
  padding-bottom: 20px;
}

.aboutMeContainer {
  background-color: #fae0fe;
  margin: 0px;
}

.aboutMeText {
  width: 70%;
  margin-left: 5%;
}

.aboutMeHeader {
  font-size: 22px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.aboutMePara {
  font-size: 18px;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 820px) {
  .headshot {
    width: 60%;
  }
  .aboutMe {
    flex-wrap: wrap;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
  }
  .aboutMeHeader {
    font-size: 20px;
    text-align: center;
  }
  .aboutMePara {
    font-size: 15px;
    text-align: center;
  }
  .aboutHeader {
    font-size: 24px;
    padding-left: 1.5rem;
  }
  .aboutMeContent {
    padding-left: 0px;
  }
}
