.button {
  background-color: #4345fc;
  color: white;
  margin-right: 8px;
  padding: 11px;
  margin-top: 5px;
  text-decoration: none;
  width: 150px;
  text-align: center;
  border-radius: 1rem;
}

.button:hover {
  background-color: #7577ff;
  transition: 0.5s;
  color: white;
}

.projectImg {
  width: 55%;
}

.projectContent {
  display: flex;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.projectContainer {
  margin-bottom: 30px;
  background-color: #fce9ff;
  margin-right: 5%;
  display: flex;
  justify-content: space-evenly;
  padding: 30px;
  padding-right: 5%;
  border-radius: 40px;
}

.projectTextConent {
  margin-left: 5%;
  margin-top: 0px;
}

.projectAbout {
  font-size: 20px;
}

@media only screen and (max-width: 820px) {
  .projectContainer {
    margin: 3%;
    padding: 6%;
  }
  .projectContent {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }
  .projectAbout {
    font-size: 15px;
  }
  .buttonsContainer {
    justify-content: center;
  }
  .button {
    font-size: 13.5px;
    padding: 0.6rem;
    border-radius: 0.8rem;
  }
  .projectImg {
    width: 80%;
  }
}
