.resumeImg {
  width: 90%;
}

.resumeContent {
  display: grid;
  place-items: center;
}

.resumeContainer {
  background-color: #f4aafe;
  padding: 0.25%;
  width: 58%;
}

.resumeButton {
  background-color: #4345fc;
  color: white;
  padding: 2%;
  margin-top: 2%;
  text-decoration: none;
  margin-bottom: 2%;
  border-radius: 1rem;
}

.resumeButton:hover {
  background-color: #7577ff;
  transition: 0.5s;
  color: white;
}

.contactHeader {
  padding-left: 1rem;
}
