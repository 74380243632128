.socialsSvg {
  width: 80%;
}

.youtubePng {
  width: 80%;
}

.socialsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23%;
  margin-top: 3%;
}

.linkedInContainer {
  display: grid;
  place-items: center;
}

.githubContainer {
  display: grid;
  place-items: center;
  padding-left: 0px;
}

@media only screen and (max-width: 768px) {
  .socialsContainer {
    width: 33%;
  }
}
