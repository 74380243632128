.navbar {
  background-color: #301aa8;
  color: white;
  display: flex;
  justify-content: right;
  position: sticky;
  top: 0;
  height: 50px;
  margin: 0px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.links {
  display: flex;
  width: 28%;
  justify-content: space-evenly;
  cursor: pointer;
}

.aboutLink:hover,
.projectsLink:hover,
.contactLink:hover {
  transition: 0.7s;
  color: #f8c9ff;
}

@media only screen and (max-width: 820px) {
  .navbar {
    width: 100%;
    font-size: 15px;
    justify-content: center;
  }
  .projectsLink {
    margin-left: 30%;
  }
  .contactLink {
    margin-left: 30%;
  }
}
