.illustration {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.App {
  background-color: #fae0fe;
  color: #301aa8;
  font-family: "Manrope", sans-serif;
}
