.footer {
  background-color: #301aa8;
  color: white;
  text-align: center;
  margin: 0px;
  padding: 1px;
}

@media only screen and (max-width: 768px) {
  .footer {
    font-size: 11.5px;
  }
}
